.playlist-page {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.playlist-wrapper {
    position: absolute;
    background-color: var(--color-tertiary);
    padding: 50px;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    overflow: hidden;
}
.playlist-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 4px 4px 4px black;
    position: relative;
}

.playlist-page .tracks {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    overflow-x: hidden;
    color: var(--text-dark);
    padding-right: 4px;
    gap: 4px;
}

.playlist-track-wrapper {
    position: relative;
    display: flex;
    min-height: 100px;
    height: 100px;
    border: 1px solid var(--color-border);
    overflow: hidden;
    cursor: pointer;
    transition-duration: var(--transition-duration);
}
.playlist-track-wrapper:hover {
    background-color: black;
}
.playlist-track-wrapper:active {
    filter: sepia(1) drop-shadow(4px 4px 4px black)!important;
}

.playlist-track-plaing img {
    animation: pAnim 30s infinite linear;
}

.playlist-track-wrapper img {
    min-width: 100px;
    max-width: 100px;
    width: 100%;
    object-fit: cover;
    transition-duration: var(--transition-duration);
    object-position: center;
    filter: grayscale(1) drop-shadow(4px 4px 4px black);
}
.playlist-track-info {
    position: relative;
    flex-grow: 1;
}
.playlist-track-name {
    font-size: 1.15rem;
    padding-top: 4px;
    padding-left: 8px;
    z-index: 2;
    position: relative;
}
.playlist-track-artist {
    font-size: 1rem;
    padding-left: 8px;
    opacity: .5;
    z-index: 2;
    position: relative;
}

.playlist-track-timer {
    background-color: var(--color-border);
    position: absolute;
    height: 100%;
    z-index: 1;
}

.horror {
    position: absolute;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    z-index: 9999;
    filter: invert(1);
    transition-duration: var(--transition-duration);
    opacity: 0;
    filter: invert(0) grayscale(1);
}

@keyframes pAnim {
    0% {
        filter: grayscale(1) invert(0)  drop-shadow(4px 4px 4px black);
    }
    25% {
        filter: grayscale(0) invert(0) drop-shadow(4px 4px 4px black);
    }
    50% {
        filter: grayscale(0) invert(0) drop-shadow(4px 4px 4px black);
    }
    55% {
        filter: grayscale(0) invert(1) drop-shadow(4px 4px 4px black);
    }
    75% {
        filter: grayscale(0) invert(1) drop-shadow(4px 4px 4px black);
    }
    95% {
        filter: grayscale(0) invert(0) drop-shadow(4px 4px 4px black);
    }
    100% {
        filter: grayscale(1) invert(0)  drop-shadow(4px 4px 4px black);
    }
}

@media (max-width: 486px) {
    .playlist-track-wrapper img {
        width: 65px;
        min-width: 65px;
        max-width: 65px;
    }
    .playlist-track-wrapper {
        min-height: 65px;
        height: 65px;
    }
    .playlist-track-name {
        font-size: 0.9rem;
    }
    .playlist-track-artist {
        font-size: 0.75rem;
    }
}