.home-page {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
}

.track-image-wrapper {
    position: absolute;
    background-color: var(--color-tertiary);
    margin: 50px;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    overflow: hidden;
    filter:  drop-shadow(4px 4px 4px black);
}
.track-image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    filter: invert(0) grayscale(0) drop-shadow(4px 4px 4px black);
}
.track-image {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition-duration: 1s;
}
.track-image:active {
    filter: invert(1) grayscale(1) sepia(1)!important;
}
.image-animation {
    animation: imageAnimation 30s infinite ease-in-out;
}

.track-artist-wrapper {
    pointer-events: none;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0 auto;
    position: relative;
}
.track-artist {
    pointer-events: none;
    position: absolute;
    transform-origin:bottom right;
    right:0;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 50px;
    bottom:0;
    font-size: 1.5rem;
    letter-spacing: 1rem;
    transform: rotate(-90deg) translateX(100%);
    width: 850px;
    overflow: hidden;
    opacity: 0;
    filter: drop-shadow(4px 4px 4px black);
}

.track-name-wrapper {
    pointer-events: none;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
}
.track-name {
    position: absolute;
    transform-origin:bottom right;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 0 50px;
    bottom: 0;
    font-size: 1.15rem;
    letter-spacing: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    width: -webkit-fill-available;
    opacity: 0;
    filter: drop-shadow(4px 4px 4px black);
}
.opacity-animation {
    animation: opacityAnimation 30s infinite ease-in-out;
}

.track-thall-wrapper {
    pointer-events: none;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
}
.track-thall {
    position: absolute;
    transform-origin: top left;
    left:0;
    top: 35%;
    height: 50px;
    display: flex;
    align-items: center;
    /* padding: 0 25px; */
    font-size: 1.5rem;
    transform: rotate(-90deg) translateX(-100%);
    overflow: hidden;
    filter: drop-shadow(4px 4px 4px black);
}
.track-thall p {
    text-align: end;
}
.track-thall span {
    padding: 0 18px;
}
.thall-animation {
    animation: thallAnimation 1s infinite ease-in-out;
}

.track-current-wrapper {
    position: absolute;
    bottom: 60px;
    right: 60px;
    z-index: 999;
}
.track-current-container {
    background-color: var(--text-dark);
    color: var(--text-light);
    padding: 10px;
    font-size: 30px;
}

.buttons {
    position: absolute;
    top: 60px;
    right: 60px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.mute,
.play,
.skip {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: var(--text-dark);
    fill: var(--text-light);
    transition-duration: var(--transition-duration);
}
.skip:hover,
.play:hover,
.mute:hover {
    background-color: var(--text-light);
    fill: var(--text-dark);
}
.play svg,
.skip svg,
.mute svg {
    width: 40px;
    height: 40px;
}

.link-wrapper {
    height: 50px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 50px;
}

.playlist-link {
    color: var(--text-dark);
    font-size: 20px;
    text-decoration: none;
    z-index: 22;
    transition-duration: var(--transition-duration);
    margin-right: 16px;
    /* width: 0; */
}
.playlist-link.active,
.playlist-link:hover {
    background-color: var(--text-dark);
    color: var(--text-light);
    /* width: 100px; */
}

.about-container {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}
.about {
    color: var(--text-dark);
    font-size: 1.25rem;
    font-weight: 500;
    margin: 50px;
    padding: 20px;
    z-index: 0;
    height: -webkit-fill-available;
    display: flex;
    filter: drop-shadow(4px 4px 4px black);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--color-tertiary);
    text-shadow: 4px 4px 4px black;
    position: absolute;
}
.about p {
    line-height: 2rem;
}
.about a {
    line-height: 2rem;
    background-color: white;
    color: var(--text-light);
    text-shadow: none;
    text-decoration: none;
    padding: 0 5px;
    margin: 0 5px;
    transition: var(--transition-duration);
}
.about a:hover {
    filter: invert(1);
}
.about h1 {
    margin-bottom: 20px;
}
.about h4 {
    text-align: end;
}

@keyframes moveImage {
    0% {
        transform: translateX(-25%);
    }
    25% {
        transform: translateX(-35%);
    }
    50% {
        transform: translateX(-25%);
    }
    75% {
        transform: translateX(-15%);
    }
    100% {
        transform: translateX(-25%);
    }
}

@keyframes opacityAnimation {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes imageAnimation {
    0% {
        opacity: 0;
        filter: invert(0) grayscale(0);
    }
    10% {
        opacity: 1;
        filter: invert(1) grayscale(1);
    }
    35% {
        filter: invert(1) grayscale(1) sepia(1);
    }
    75% {
        opacity: 1;
        filter: invert(1) grayscale(1) sepia(0.25);
        filter: invert(1) grayscale(1);
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        filter: invert(0) grayscale(0);
    }
}

@keyframes thallAnimation {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: var(--text-dark);
        color: var(--text-light);
    }
    100% {
        background-color: transparent;
    }
}

@media (max-width: 486px) {
    .mute, .play, .skip {
        padding: 5px;
    }
    .play svg, .skip svg, .mute svg {
        width: 20px;
        height: 20px;
    }
    .track-thall {
        top: 30%;
    }
    .playlist-link {
        font-size: 16px;
    }
    .track-name {
        font-size: 1rem;
    }
    .track-artist {
        font-size: 1rem;
    }
    .about {
        font-size: 0.85rem;
        justify-items: unset;
    }
}