* {
  margin: 0;
  padding: 0;
  user-select: none;
}

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-tertiary);
  color: var(--text-dark);
  overflow: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
:root {
  --border-radius: 4px;
  --transition-duration: 200ms;
  --box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  
  --color-main: #202020;
  --color-secondary: #363636;
  --color-tertiary: #181818;
  --color-primary: #ff9000;
  
  --color-border: #4b4b4b;
  
  --color-success: #61D174;
  --color-danger: #E23645;
  --color-warning: #EDC25E;
  --color-info: #00b3ff;
  
  --text-dark: #ffffff;
  --text-light: #000000;
}

.page {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: var(--color-tertiary);
}
*::-webkit-scrollbar-thumb {
  min-height: 65px;
  background-color: var(--color-border);
  /* border-radius: var(--border-radius); */
  border: 1px solid var(--color-border);
}

iframe {
    border-radius: var(--border-radius);
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.flex {
  display: flex;
}
.flex-end {
  justify-content: flex-end;
}
.text-right {
  text-align: right;
}
.link {
  text-decoration: none;
  color: var(--text-dark);
  transition-duration: var(--transition-duration);
  padding: 4px 8px;
}
.link:hover {
  border-radius: var(--border-radius);
  background-color: var(--color-border);
}
.mb-1 {
  margin-bottom: 1rem;
}
.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}